require('./bootstrap');
require('./../../node_modules/jquery-ui/ui/effect.js')

import $ from 'jquery';
window.$ = window.jQuery = $;


/*
*  Modify bouton wishlist
*/
let checkboxModif = false;
var btnSaveWishlist = document.getElementsByClassName("btn-save-wishlist");
btnSaveWishlist = btnSaveWishlist[0];
btnSaveWishlist.style.display = 'none';

$('.event-checkbox input[type="checkbox"]').bind('click', function () {
    // console.log("this.", this);
    // console.log("this.value", this.value);
    checkboxModif = true;
    btnSaveWishlist.style.display = "block";
    document.getElementById("lst-download-wishlist").style.display = "none";
});
$('#btn-save-wishlisst').bind('ckick', function() {
    btnSaveWishlist.style.display = "none";
    document.getElementById("lst-download-wishlist").style.display = "block";
})

/*
* scroll to the top
*/
window.onscroll = function() {affBtnScrollTop()};

function affBtnScrollTop() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("btnScrollTop").style.display = "block";
    } else {
        document.getElementById("btnScrollTop").style.display = "none";
    }
}

$('#btnScrollTop').bind('click', function (event) {
    $('html, body').stop().animate({
        scrollTop: 0
    }, 1500, 'easeInOutExpo');
    event.preventDefault();
});

/*
*  Select year wishlist
*/
$(document).on('change', '#selectYear', function (e) {
    e.preventDefault();
    window.location = "/wishlist/annee/"+$('#selectYear').val();
})